<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" md="6">
        <base-material-card
          color="success"
          inline
          comment-icon="mdi-email"
          title="Register email content"
          class="px-5 py-3"
        >
          <v-simple-table>
            <template>
              <tbody>
                <tr>
                  <th>{{ $t("subject") }}</th>
                  <td>{{ item.title }}</td>
                </tr>
                <tr>
                  <th>{{ $t("writer") }}</th>
                  <td>{{ item.writer }}</td>
                </tr>
                <tr>
                  <th>{{ $t("content") }}</th>
                  <td>{{ item.content }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-card-actions class="pl-0">
            <v-btn color="success" min-width="100" :href="urlEdit"
              >{{ $t("edit") }}
            </v-btn>
            <v-btn color="error" min-width="100" @click="submitDelete"
              >{{ $t("delete") }}
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" top centered>
      {{ notificationMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    urlIndex: "/contents/contents-email",

    item: {},

    snackbar: false,
    notificationMessage: "",
  }),
  computed: {
    urlEdit() {
      return `/contents/contents-email/${this.$route.params.id}/edit`;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        console.log("ROUTE show", this.$route.params.id);
        const res = await this.authGet(
          `/api/contents/contents-email/${this.$route.params.id}`
        );
        console.log(res.data.contentsEmail);
        this.item = res.data.contentsEmail;
      } catch (e) {
        const { response } = e;
        if (response) {
          this.showNotification(response.data);
        } else {
          this.showNotification(e);
        }
      }
    },

    async submitDelete() {
      try {
        const res = await this.authDelete(
          `/api/contents/contents-email/${this.$route.params.id}`
        );
        console.log("after delete,", res);
        if (res.data.result) {
          // this.showNotification("Deleted");
          location.href = this.urlIndex;
        }
      } catch (e) {
        console.error(e);
      }
    },

    showNotification(msg) {
      this.notificationMessage = msg;
      this.snackbar = true;
    },
  },
};
</script>
